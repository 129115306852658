const Clar = (props)=>{
    return(
        <>

    { props && props.data && props.data == 'english' && 
          <blockquote>
                For clarifications/queries, please contact IANS NEWS DESK at:
                <span className="quoter">
                    <ul>
                    <li><i aria-hidden="true" className="fa fa-phone"></i> +91-120-4822400</li>
                    <li><i aria-hidden="true" className="fa fa-mobile"></i> +91-9311830312</li>
                    <li><i aria-hidden="true" className="fa fa-envelope"></i> support@ians.in</li>
                    </ul>
                </span>
            </blockquote> 
             }  
        { props && props.data && props.data == 'hindi' && 
          <blockquote>
                स्पष्टीकरण/प्रश्नों के लिए, कृपया आईएएनएस न्यूज डेस्क से संपर्क करें:
            <span className="quoter">
                <ul>
                <li><i aria-hidden="true" className="fa fa-phone"></i> +91-120-4822400</li>
                <li><i aria-hidden="true" className="fa fa-mobile"></i> +91-9311830312</li>
                <li><i aria-hidden="true" className="fa fa-envelope"></i> support@ians.in</li>
                </ul>
            </span>
        </blockquote>
             }        
            { props && props.data && props.data == 'yesEng' && 
                <div className="pr-quote">
                    <blockquote>
                    Disclaimer:
                    <p>The content provided in this section is part of a third party press release service and does not reflect the editorial views or opinions of IANS. The responsibility for the accuracy, authenticity, and legality of the information lies solely with the content provider. IANS assumes no liability for the content published under this arrangement and encourages readers to verify the information independently before consuming it.</p>
                    </blockquote>  
                </div>
            }   
            { props && props.data && props.data == 'yesHin' && 
                <div className="pr-quote">
                    <blockquote>
                    Disclaimer:
                    <p>The content provided in this section is part of a third party press release service and does not reflect the editorial views or opinions of IANS. The responsibility for the accuracy, authenticity, and legality of the information lies solely with the content provider. IANS assumes no liability for the content published under this arrangement and encourages readers to verify the information independently before consuming it.</p>
                    </blockquote>  
                </div>
            }              
        </>
    )
}

export default Clar