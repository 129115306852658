import React,{useState, useEffect,useRef, useCallback} from 'react';
import axios from 'axios';
import Footer from './Footer'
import Header from './Header'
import {NavLink, useParams} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { format, parseISO } from 'date-fns';
import { useHistory } from "react-router-dom";
import HomeSlider from './HomeSlider';
import HeadingTab from './HeadingTab';
import Top from './Top';
import {useStickyBox} from "react-sticky-box";
import VideoCat from './videoCat'

const PhotoWire = ()=>{

const stickyRef = useStickyBox({offsetTop: 200, offsetBottom: 1000})
const [topData, setTopData] = useState()
const [engData, setEngData] = useState()
const categoryList = [
    {
        name:'General',
        slug:'national/1'
    },
    {
        name:'International',
        slug:'international/1'
    },
    {
        name:'Politics',
        slug:'politics/1'
    },
    {
        name:'Entertainment',
        slug:'entertainment/1'
    },    
    {
        name:'Sports',
        slug:'sports/1'
    },    

    {
        name:'Business',
        slug:'business/1'
    },
    {
        name:'Fashion',
        slug:'fashion/1'
    },
    {
        name:'Cinema',
        slug:'cinema/1'
    },
    {
        name:'WildLife',
        slug:'wildLife/1'
    },
    {
        name:'Maha Kumbh 2025',
        slug:'kumbh2025/1'
    },
    // {
    //     name:'T20 World Cup 2024',
    //     slug:'t20worldcup2024'
    // },
    // {
    //     name:'Paris Olympics 2024',
    //     slug:'olympics2024/1'
    // }     
]

// const [backImageHome, setbackImageHome] = useState('')
const [cateText, setCategoryName] = useState('all')
const [cateName, setCateName] = useState('')
const [searchText, setSearchText] = useState('')
const [inputSearch, setSearch] = useState()
const inputRef = useRef(null)
const {categoryId, search, offset,} = useParams()
const [currentPage, setCurrentPage] = useState(1)
const [pageList, setPageList] = useState([1,2,3])
const [lastPage, setLastPage] = useState(1)
const [lt, setLt] = useState(5)
const history = useHistory()
const [requestFlag, setRequestFlag] = useState(true)
const formatDate = (timestamp)=>{
    const date = parseISO(timestamp)
    return format(date, 'MMMM dd, yyyy h:mm a')
}
const getCatgoryData=(obj)=>{
    if(obj != cateText){
        setCategoryName(obj)
        setCurrentPage(1)
        history.push('/english-wire/1');
    }
    //fetchData()
}
const getTopNewsData =()=>{
    axios
    .get(
    `https://cms.iansnews.in/api/elastic/imagenews/list/?top_news=true`
    )
    .then((res) => {
    const latestStories =
        res.data.results &&
        res.data.results.length > 0 &&
        res.data.results.map((item) => {
 
        return {
            id: item.id,
            image: item.image,
            title: item.title,
            slug:'/photo-event/'+item.slug,
            dateTime: item.created_at ? formatDate(item.created_at): ''
        }})
        setTopData(latestStories)
    })
 }
const searchData=(data)=>{
    setSearchText(data)
    history.push('/photo-wire/'+data+'/1');

}

const fetchData = async (page) => {
   // alert(categoryId)
   
    let getApii = `https://cms.iansnews.in/api/elastic/imagenews/list/?language=english`;
    if(categoryId && categoryId !='all'){
        //cateText = `&tags=${cateText}`;
       getApii += `&tags=${categoryId}`;
    }
    if(inputSearch){
       // getApii += `&query=${inputSearch}`;
    }
    
    getApii += `&page=${page}`;
    page = page ? page: 1;

    axios.get(getApii)
    .then(res => {
    // if(lastPage == 1){
         setLastPage(res.data.numpages);
    // }
    const getStories =
    res.data.results &&
    res.data.results.length > 0 &&
    res.data.results.map((item) => {
    // let tgSt= item.tags.filter(it => it.name === 'Business')
    return {
        content: item.caption,
        id: item.id,
        image: item.image ? item.image : 'https://iansportalimages.s3.amazonaws.com/all/ians_df_img.jpg',
        tags:item.tags,
        image_count: item.image_count,
        dateTime: item.created_at ? formatDate(item.created_at): '',
        title: item.title,
        slug:'/photo-event/'+item.slug
        // slugType: tgSt && tgSt.length > 0 ? true : false,
    }})
    // setbackImageHome(`background: url(${res.data.results[0].image}) no-repeat 50% 50%`)
    setEngData(getStories);
    setLt(res.data.numpages);
    setRequestFlag(false);
    })
    .catch(err => {
    // console.log('Google api calendar error', err)
    })
}

const handleNextPage = () => {
    if(lt > currentPage){
        setCurrentPage(parseInt(currentPage) + 1)
    }
}

const [isVisible, setIsVisible] = useState(false);
const scrollToTop = () => {
    window.scrollTo({
    top: 0,
    behavior: "smooth"
})}
const handlePrevPage = () => {
    if(currentPage > 1){
        setCurrentPage(currentPage - 1);
    }
}
const inputSData=(event)=>{
    setSearch(event.target.value);
  }
const onSubmit = (e) => {
    e.preventDefault()

      let path = '/photo-search/'+inputSearch;
        //SetSHead('template-search')
        //window.reload()
        window.location.pathname=path;
        history.push(path);
    // console.log(conFom)
}
const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
        setIsVisible(true)
    } else {
        setIsVisible(false)
    }
}
const [startPg, setStartPage] = useState(1)
const inputGSData=(event)=>{
    setStartPage(event.target.value)

  }
const GoToPage=(e)=>{
    e.preventDefault()
    setCurrentPage(startPg);
    let pathh = '/photo-wire/all/'+startPg
    

    history.push(pathh)
    
}
useEffect(() => {
    if(categoryId){
        setCategoryName(categoryId)
    }
    setCurrentPage(offset)
    setStartPage(offset)
    if(currentPage == offset){
        fetchData(currentPage)
        setStartPage(currentPage)
    }
    getTopNewsData()
    scrollToTop()
    if(!localStorage || !localStorage.accessToken || !localStorage.photoStatus){
        history.push('/login');
    }
    window.addEventListener("scroll", toggleVisibility)
    return () => window.removeEventListener("scroll", toggleVisibility)
//  .catch(err => { console.log('Google api calendar error', err) })
}, [currentPage, categoryId, search, offset])
return(
<>
<Helmet>
   <meta name="viewport" content="width=device-width, initial-scale=1.0" />
   <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
   <title>Indo-Asian News Service-{cateName ? cateName.toUpperCase() : ''}</title>
   <meta data-meta-title="IANS IN" name="keywords" content={''} />
   <meta data-meta-title="IANS IN" name="description" content="Stay informed with IANS LIVE News, your ultimate source for the latest updates, live news, and business developments. Get the most comprehensive coverage of news in India, including Bollywood updates and breaking headlines. Stay up-to-date with current events and global news, from sports updates to the latest happenings in India. Our news portal brings you the top stories, entertainment buzz, national affairs, and political developments, making it your go-to destination for staying in the know." />
</Helmet>
<Header currU={'/photo-wire/all/1'}/>
<HomeSlider/>

<main id="main">
    <div className="container video-newsec photo-cont">
        <Top  title="TOP PHOTOS" data={topData} pTag={true}/>
        <section id="about" className="about">
                <HeadingTab />
                <div className="row">
                        <div className="col-lg-9 order2">
                        <div className="row post-loadmore-wrapper">
                            <div className="main-heading">

                            <div className="video-tab-sec photo-tab-sec">
                                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <NavLink to={`/photo-wire/all/1`} className='nav-link active'>ALL Events</NavLink>
                                    </li>
                                    <li className="nav-item">
                                    {localStorage && localStorage.accessToken &&
                                        <NavLink className='nav-link' to={`/all-photo-wire/all/1`}>ALL Photos</NavLink>
                                        }
                                       {(!localStorage || !localStorage.accessToken) &&
                                        <NavLink className='nav-link' to={`/login`}>ALL Photos</NavLink>
                                        }                                     
                                     </li>
                                    {/* <li className="nav-item">
                                        <NavLink className={`${categoryId && categoryId == 'short_videos' ? 'nav-link active' : 'nav-link'}`} to={`/video-wire/short_videos/1`}>Short Videos</NavLink>
                                    </li>                   */}
                                    </ul>
                                    
                                    <div className="search-form-allphoto">
                                    <form onSubmit={onSubmit}>            
                                        <input type="search" ref={inputRef} placeholder="Search...." onChange={inputSData} name="search" value={inputSearch}/>
                                        <span className="input-group-text border-0" onClick={onSubmit}><i className="bi bi-search" aria-hidden="true"></i></span>
                                    </form>                      
                                    </div>
                                </div>
                            </div>

                        { engData && engData.length > 0 && engData.map((PhotoTemp ,pt)=>{
                            return(
                                <>
                                <div className="col-xl-4 col-lg-4 col-md-6 mb-3 sec-left img-col4" key={pt}>
                                    <NavLink title={PhotoTemp.title} to={PhotoTemp.slug}>
                                        <div className="thumb w-100  album-thumb">
                                            <img className="thumb-image w-100" src={PhotoTemp.image}/>
                                            <div className="photo-count">
                                                <div className="content">
                                                    <div className="number">{PhotoTemp.image_count}</div>
                                                    {/* <div className="label">PHOTOS</div> */}
                                                </div>
                                            </div>
                                            {/* <style>
                                                {`
                                                .album-thumb:after {
                                                        ${backImageHome};
                                                    }
                                                `}
                                            </style> */}
                                            <div className="dec-img">
                                                {/* <span className="pic-count">PHOTOS: {PhotoTemp.image_count}</span> */}
                                                <span className="des-pic-title">{PhotoTemp.title}</span>
                                                <span className="des-pic-label">{PhotoTemp.dateTime}</span>
                                                {/* <div className="videotag">
                                                    <div className="videotag__container">
                                                    {PhotoTemp.tags && PhotoTemp.tags.length > 0 && PhotoTemp.tags.map((tgg,t)=>{
                                                        return(
                                                            <NavLink className="videotag__item"  to={`/photo-wire/${tgg.slug}/1`} key={t}>{tgg.name}</NavLink>
                                                    )})}

                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                </>
                        )})}
                        {lt && lt > 0 &&
                            <nav aria-label="Page navigation example">
                            <ul className="pagination">
                            { currentPage && currentPage > 1 &&                              
                                <li className="page-item" onClick={handlePrevPage}>
                            <NavLink to={`/photo-wire/${cateText}/${currentPage-1}`} className="page-link" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                            </NavLink>
                                </li>
                            }
                                <li className="page-item" onClick={()=>{
                                    setCurrentPage(currentPage)
                                    }}>
                            <a className="page-link active" href="#">{currentPage}</a></li>
                                <li className="page-item" onClick={()=>
                                {
                                    setCurrentPage(currentPage+1)
                                }}>
                                <NavLink to={`/photo-wire/${cateText}/${parseInt(currentPage)+1}`} className="page-link">{parseInt(currentPage)+1}</NavLink>
                                </li>
                                <li className="page-item" onClick={()=>
                                {
                                    setCurrentPage(currentPage+2)
                                }}>
                                <NavLink to={`/photo-wire/${cateText}/${parseInt(currentPage)+2}`} className="page-link">{parseInt(currentPage)+2}</NavLink>
                                </li>
                                <li className="page-item" onClick={handleNextPage}>
                                <NavLink to={`/photo-wire/${cateText}/${parseInt(currentPage) + 1}`} className="page-link" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                </NavLink>
                                </li>
                                <li className="go_page"> Page 
                                        <input type="number" name="quantity" min={startPg} max={lastPage}  onChange={inputGSData} value={startPg}/>
                                        <i className="go_btn" aria-hidden="true" onClick={GoToPage}>GO</i>
                                </li>                                  
                            </ul>
                            </nav>
                            }
                        </div>
                    </div>
                    <div className="col-lg-3 border-left-sec sidebar-pic order1">
                        <VideoCat data={categoryList} slug={'/photo-wire/'} ref={stickyRef}/>
                    </div>
            </div>
        </section>
    </div>
</main>               
<Footer />  
</>
)
}
export default PhotoWire