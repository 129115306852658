import Footer from './Footer'
import Header from './Header'
import React,{useState, useEffect} from 'react';
import axios from 'axios';
import FileSaver from "file-saver";
import {useParams} from 'react-router-dom';
import {NavLink} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import Permision from './Permision'
import { format, parseISO } from 'date-fns';
import copy from "copy-to-clipboard"
const VideoElementDetail = () =>{
   const history = useHistory();
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = (url) => {
       setVideoUrl(url);
       setShow(true)
   }

   const [videoUrl, setVideoUrl] = useState('https://iansportalimages.s3.amazonaws.com/videos_watermark/28-09-2023-11.mp4');
   const videoStartPlay = (url)=>{
       setVideoUrl(url);
   }   
const {newsId} = useParams();
const [textToCopy, setTextToCopy] = useState('')
const [isLoading, setIsLoading] = useState(false);
const [collectionData, setCollectionData] = useState([]);
const [tgData, setTagsData] = useState([]);
const [login, setLogin] = useState(true)
const [errorMsg, setErrorMsg] = useState('');
const [latData, setLatestData] = useState({});
const LatestData =()=>{
   axios
   .get(
   `https://cms.iansnews.in/api/elastic/video/list/?&page=1`
   )
   .then((res) => {
   const latestStories =
       res.data.data &&
       res.data.data.length > 0 &&
       res.data.data.slice(0, 7).map((item) => {
         let tgSt= item.tags.filter(it => it.name === 'Business')

       return {
           id: item.id,
           image: item.thumbnail,
           title: item.title,
           slug:'/video-wire-detail/'+item.slug,
           dateTime: item.created_at ? formatDate(item.created_at): ''
       }})
   setLatestData(latestStories)
   })
}
const formatDate = (timestamp)=>{
   const date = parseISO(timestamp);
   return format(date, 'MMMM dd, yyyy h:mm a')
}
const copyToText = async () => {
   const tempDiv = document.createElement('div')
   tempDiv.innerHTML = collectionData.copy_content

   // Remove most HTML tags except for <p>
   let copyt = ''

   // Replace specific <p> tags with double line breaks
   //copy = copy.replace(/<p><\/p>/g, '\n\n');

   // Keep specific <p> tags at the bottom
   const specificTags = tempDiv.querySelectorAll('p')
   specificTags.forEach((tag) => {
      copyt += '\n\n' + tag.innerHTML;
   });

   // Combine title, short_desc, and modified content
   let final_copy = '';
   //if(final_copy){
      final_copy = `${collectionData.title}\n\n${collectionData.content} ${copyt}`
      final_copy=final_copy.replace("<br>","\n")
      final_copy=final_copy.replace("<p>","")
      final_copy=final_copy.replace("</p>","")

      final_copy=final_copy.replace('<br>','\n')
      final_copy = final_copy.replace(/&nbsp;/g, ' ')
      final_copy=final_copy.replace('<br data-mce-bogus="1">','')
      copy(final_copy);
     // handleSubmit(collectionData.title, collectionData.id)
   //}
   // Copy the final content to the clipboard
   //await navigator.clipboard.writeText(final_copy);
   }
  const imageDownload=(url)=>{
   setIsLoading(true);
            let modifiedUrl = url.split('/').pop();
          // downloadResource(url, modifiedUrl);
          if (!modifiedUrl) modifiedUrl = url.split('\\').pop().split('/').pop();
          let updateUrl = url.replace("videos_watermark", "videos");
          fetch(updateUrl)
          .then(response => response.blob())
          .then(blob => {
            console.log('ÍANSSS',blob);
            if(blob && blob.size && blob.size > 1000){
              setIsLoading(false);
            }
              const fileSaver = FileSaver.saveAs(blob, modifiedUrl);
              fileSaver.onwriteend = function () {
              console.log('Download completed.');
              // alert();
             setIsLoading(false);
              };
              fileSaver.onerror = function () {
              console.error('Download failed.');
              };
          })
          .catch(e => console.error(e));
         
  }
  const videoDwlUrl=(url, type)=>{
   setIsLoading(true);
            if(type && type == 2){
               url = 'https://iansportalimages.s3.amazonaws.com/videos/'+url;
            }
            let modifiedUrl = 'videos/' + url.split('/').pop();
          // downloadResource(url, modifiedUrl);
          if (!modifiedUrl) modifiedUrl = url.split('\\').pop().split('/').pop();
          let updateUrl = url.replace("videos_watermark", "videos");
          fetch(updateUrl)
          .then(response => response.blob())
          .then(blob => {
            console.log('ÍANSSS',blob);
            if(blob && blob.size && blob.size > 1000){
              setIsLoading(false);
            }
              const fileSaver = FileSaver.saveAs(blob, modifiedUrl);
              fileSaver.onwriteend = function () {
              console.log('Download completed.');
              // alert();
             setIsLoading(false);
              };
              fileSaver.onerror = function () {
              console.error('Download failed.');
              };
          })
          .catch(e => console.error(e));
          if(type == 2){
            handleSubmit(collectionData.title, collectionData.id, 'Video - Element')

          }else if(type == 1){
            handleSubmit(collectionData.title, collectionData.id, 'Video - Element')

          }     
  }
  const handleSubmit = (title, id, VType) => {
   //e.preventDefault();
   let formCData = {
      "content_id":  id ? id : '',
      "username": localStorage && localStorage.userName ? localStorage.userName : '',
      "content_type": VType,
      "content_title": title ? title : ''
   }
   axios.post('https://cms.iansnews.in/api/download_records/', formCData)
     .then(response => {
       console.log('Success:', response.data);
       // Optionally, reset form fields

     })
     .catch(error => {
       console.error('Error:', error);
     });
 };
  const getData = async() =>{
 
   const apiD =`https://cms.iansnews.in/api/elastic/video/detail/${newsId}
   `
   const config = {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken ? localStorage.accessToken :''}`,
      }
    }
   axios.get(apiD, config)
.then(res =>
  {
   let main_content_string = res.data.data[0].content;
   let iansIndex =  main_content_string;
   //let new_main_content_string = '';

    
   let imgByLine = '';
   if(res.data.data[0].byline =='ians'){
      imgByLine = 'https://iansportalimages.s3.amazonaws.com/all/ians-author.jpg';
   }else{
       if(res.data.data[0] && res.data.data[0].byline){
           let newIi = res.data.data[0].byline;
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
           newIi = newIi.replace(" ", "-");
     
           newIi = newIi.toLowerCase();
           imgByLine = 'https://iansportalimages.s3.amazonaws.com/author/'+newIi+'.jpg';
       }

   }
  
   setCollectionData({
   caption:res.data.data[0].caption,
   content: res.data.data[0].description,
   copy_content: res.data.data[0].content,
   id: res.data.data[0].id,
   image: res.data.data[0].thumbnail,
   byline: res.data.data[0].byline,
   title: res.data.data[0].title,
   images_thumbnails: res.data.data[0].images_thumbnails,
   url: res.data.data[0].url,
   dateTime: res.data.data[0].created_at ? formatDate(res.data.data[0].created_at): '',
   slug:'/'+res.data.data[0].slug,
   video_file : res.data.data[0].video_file,
   video_duration : res.data.data[0].video_duration,
   video_preview : res.data.data[0].video_preview,
   tags : res.data.data[0].tags,
   image_caption: res.data.data[0].image_caption
 })
 
 const tagData = res
 && res.data 
 && res.data.results 
 && res.data.results.length > 0 
 && res.data.data[0] 
 && res.data.data[0].tags 
 && res.data.data[0].tags.length > 0 
 && res.data.data[0].tags.map((taggg,tt) => {
  return {
    id: taggg.id,
    name: taggg.name,
    slug: taggg.slug
  }
})
   console.log("Your new array of modified objects here",collectionData)
setTagsData(tagData);
})
.catch(err => {
   console.log('Google api calendar error', err) 
   if(err && err.response && err.response.data && err.response.data.detail
   ){if(err.response.data.detail == 'Invalid token header. No credentials provided.'){
      setErrorMsg(`If you are a subscriber, please login to view this page. Otherwise please contact IANS marketing team.`)
     // history.push('/permision');
   }else if(err.response.data.detail && err.response.data.detail.length > 0 ){
      setErrorMsg(err.response.data.detail)
     // history.push('/permision');
   }
   }})
}
const [isVisible, setIsVisible] = useState(false);
const scrollToTop = () => {
    window.scrollTo({
    top: 0,
    behavior: "smooth"
})}
const toggleVisibility = () => {
   if (window.pageYOffset > 500) {
       setIsVisible(true)
   } else {
       setIsVisible(false)
   }
}
 useEffect(() => {
   // if(!localStorage || localStorage.length == 0 || !localStorage.accessToken || !localStorage.videoEngStatus || localStorage.videoEngStatus=='false' || localStorage.videoEngStatus == false){
   //    history.push('/permision');
   // }
   if(!localStorage || localStorage.length == 0 || !localStorage.accessToken){
      localStorage.setItem('redirectTo', newsId ? '/'+newsId :'')
      localStorage.setItem('langee', 'video')
      history.push('/login')
  }else if(localStorage && localStorage.length > 0 && localStorage.accessToken && (!localStorage.videoEngStatus || localStorage.videoEngStatus=='false' || localStorage.videoEngStatus == false)){
      //history.push('/permision')
   }   
   LatestData()
   getData()
   scrollToTop()

   window.addEventListener("scroll", toggleVisibility)
   return () => window.removeEventListener("scroll", toggleVisibility)
}, [newsId])

    return(
        <>
         <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta http-equiv="Content-Type" content="text/html; charSet=utf-8" />
            <title>Indo-Asian News Service-{collectionData.title ? collectionData.title.toUpperCase() : ''}</title>
            <meta data-meta-title="IANS IN" name="keywords" content={collectionData.keywords ? collectionData.keywords.toUpperCase() : ''} />
            <meta data-meta-title="IANS IN" name="description" content={collectionData.short_desc} />
            <meta property="og:title" content={collectionData.title} />
            <meta property="og:description" content={collectionData.short_desc} />
            <meta property="og:image" content={collectionData.image} />
            <meta property="og:url" content={'https://iansphoto.in/detail'+collectionData.slug} />
            <meta property="og:type" content="article" />
        </Helmet>
        
        { !errorMsg &&
        <Header/>  
        }
         { !errorMsg &&
       
         <main id="main">
         {!isLoading &&   
            <section id="about" className="about">
               <div className="container">
                  <div className="row video-newsec">
                     <div className="col-lg-9 videosec">
                     <div id="print">
                        <div className="full-article">
                           <div className="videothumb">
                              <video controlsList="nodownload" autoPlay controls  loop src={collectionData.video_preview}><source type="video/mp4" src={collectionData.video_preview}/></video>
                           </div>
                        </div>
                        <div className="ians-userdetail-share ians-story-author-section vdbtn">
                           <div className="downloadhbtn">

                              <a alt="Download file" href="#" title="Download video"  onClick={()=>{
                                    videoDwlUrl(collectionData.video_file, 1)
                                 }}>
                                 <span>Download edited video</span>
                                 <i alt="Download file" className="bi bi-download"></i>
                              </a>
       

                           { collectionData.video_duration   &&
                               <a alt="Download file" href="#" title="Download video"  onClick={()=>{
                                    videoDwlUrl(collectionData.video_duration, 2)
                                 }}>
                                 <span>Download RAW video</span>
                                 <i alt="Download file" className="bi bi-download"></i>
                              </a>
                           }


                                       <a href="#" title="Copy Text" onClick={copyToText}>
                                       <span>Copy Text</span>
                                          <i alt="Download file" className="copyicon"></i>
                                       </a>


                           </div>
                           <div className="ians-author-detial-left">
                              <div className="ians-author-brand-detial">
                                 <ul>
                                 {collectionData.tags && collectionData.tags.length > 0 && collectionData.tags.map((temTg , tg)=>{
                                    return(
                                       <>
                                       <li key={tg}>{temTg.name}</li>
                                       </>
                                    )
                                 })}
                                    <li>IANS</li>
                                    <li>{collectionData.dateTime}</li>
                                 </ul>
                              </div>
                           </div>
                           {/* <div className="share-social-icons">
                              <ul>
                                 <li className="share-li" onClick={()=>{
                      videoDwlUrl(collectionData.video_file)
                    }}>
                                    dolwnhuhb<a alt="Download file" href="#" title="Download video">
                                       <i alt="Download file" className="bi bi-download"></i>
                                    </a>
                                 </li>
                              </ul>
                           </div> */}
                        </div>
                        <div className="inews">
                           <h2 className="title" id="newsheading">{collectionData.title}</h2>
                           <div className="full-article-details" dangerouslySetInnerHTML={{ __html:  collectionData.content }} />
                           
                        </div>
                     </div>
                     
                     </div>
                     <div className="col-lg-3 latest-news-sec right-sec">
                        <div className="main-heading">
                              <h2 className="heading">LATEST VIDEO</h2>
                        </div>
                        <ul className="list-unstyright">
                           {latData && latData.length > 0 && latData.map((lattt, l)=>{
                              return(
                                 <>
                                    <li key={l}>
                                       <figure>
                                          <figcaption className="tabtextarea-unstyright">
                                             <NavLink title={lattt.title} alt={lattt.title} to={lattt.slug}>
                                                <h3 className="title">{lattt.title}</h3>
                                             </NavLink>
                                          </figcaption>
                                          <div className="img-area-unstyright">
                                             <NavLink to={lattt.slug}>
                                                <img src={lattt.image} title={lattt.title} alt={lattt.title}/>
                                             </NavLink>
                                             <div className="img-box-wrap">
                                                <i className="bi bi-play" aria-hidden="true"></i>
                                             </div>
                                          </div>
                                       </figure>
                                    </li>
                                 </>
                              )
                           })}
                        </ul>
                     </div>

                  </div>
               </div>
            </section>
         }
         {isLoading && 
         <section className="upcoming-meetings" id="meetings">
            <div className="container">
              <div className="row">
                <h1 className="lod-hed">Downloading....</h1>
                <div className="loader">
                </div>
              </div>
            </div>
         </section>
         }
         </main>   
         }   
         {errorMsg &&
         <Permision />
         } 
      { !errorMsg &&
        <Footer/>  
        }
            
        </>
    )
}

export default VideoElementDetail